<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline title="人群管理"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
      <template #header="params">
        <div class="search_box">
          <el-row :gutter="10">
            <el-col :span="6">
              <div class="search_item">
                <div>人群ID：</div>
                <el-input v-model="searchData.crowdNo"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="search_item">
                <div>人群名称：</div>
                <el-input v-model="searchData.crowdName"></el-input>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top: 10px;">
            <el-col :span="4" :offset="20">
              <el-button @click="pageHandler(params, 1, 'reset')">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </template>
      <template #navBar>
        <div class="table_nav_bar">
          <router-link to="/crowdManagement/add">
            <el-button type="primary">创建圈选人群</el-button>
          </router-link>
<!--          <router-link to="/crowdManagement/group">-->
<!--            <el-button>创建组合人群</el-button>-->
<!--          </router-link>-->
          <el-button type="text" icon="el-icon-refresh-left" @click="updataListFn">更新数据</el-button>

        </div>
      </template>
      <el-table-column label="人群ID" fixed="left" prop="crowdNo" :min-width="120"></el-table-column>
      <el-table-column label="人群名称" prop="crowdName" :min-width="200"></el-table-column>
      <el-table-column label="覆盖人数" prop="coverNum" :min-width="100">
        <template slot-scope="scope">
            <el-button type="text" @click="handleUserView(scope.row)">
              {{ scope.row.coverNum }}
            </el-button>
        </template>
      </el-table-column>
      <el-table-column label="规则" prop="rule" :min-width="180">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" :content="scope.row.rule" placement="top">
            <div class="table_row_rule">{{ scope.row.rule }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="人群创建时间" prop="ctime" :min-width="180"></el-table-column>
      <el-table-column label="操作" fixed="right" prop="" width="250">
        <template slot-scope="scope">
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(1, scope.row)">人群洞察</el-button>
          <el-button class="handle_btn" type="text" size="mini" v-if="scope.row.updateFlag===1"
                     @click="tableHandle(3, scope.row)">重新计算
          </el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(2, scope.row)">删除</el-button>

        </template>
      </el-table-column>
    </my-table>
  </div>
</template>

<script>
/* eslint-disable */
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table } from "@/utils/decorator";
import QRCode from "qrcodejs2";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
    data() {
    //这里存放数据
      return {
        listData: [{},{},{}],
        // 搜索数据
        searchData: {
          crowdName: "",          //人群名称
          crowdNo: "",            //人群编号
        },
        searchKey: "customerName", //下拉input所选Key
        listLoading: false, //列表loading
        channelNameList: [                     //渠道名称list
          { channel: '', linkName: '' ,channelMobile: ''}
        ],
        promotionDialogVisible: false,        //推广信息弹窗
        rowActivityCode: "",                  //当前点击的活动code
        activityPopularizeList: [],           //活动推广列表
        url: "",
        rewardDialogVisible: false,
        commissionConfList: [],
        orderItems: [{
          "item":"createTime",
          "type":"desc"
        }],
      };
    },
  filters: {
    stateFilter: function(val) {
      
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num, type) {
      if(type == 'reset'){
        this.resetSearchData();
      }
      this.getActivityList(params, num ? num : params.pageNum, params.pageSize);
    },
    // 表格内操作
    tableHandle(type, row) {
      console.log("type:" + type + " row:" + JSON.stringify(row));
      switch (type) {
        case 1:     //人群洞察
          this.$router.push(`/crowdManagement/crowdChart?crowdNo=${row.crowdNo}`);
          break;
        case 2:     //删除
          this.$confirm('是否删除此人群?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$store.dispatch('management/crowdManagementDelete',{
              crowdNo: row.crowdNo
            }).then(res => {
              this.$refs.list.init();
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
          break;
        case 3:     //重新计算
          this.$store.dispatch('management/againCalculateCoverNum',{
            crowdNo: row.crowdNo
          });
          this.$message.success("[" + row.crowdNo + "]更新任务后台运行中，请稍后查看");

        default:
          break;
      }
    },
    // 跳转活动详情
    gotoDetail(row){
      this.$router.push(`/flowActivity/add?activityCode=${row.activityCode}`);
    },

    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        crowdName: "",          //人群名称
        crowdNo: "",            //人群编号
      }
    },

    // 更新用户数据
    updataListFn(){
      this.$store.dispatch("management/crowdManagementUpdata", {});
      this.$message.success("更新任务后台运行中，请稍后查看");
    },



    // 获取活动列表
    getActivityList(params,pageNum,pageSize) {
      this.listLoading = true;
      this.$store.dispatch('management/crowdManagementList',{
        ...this.searchData,
        pageNum,
        pageSize,
      }).then(res => {
        if(res.code == 0){
          this.listData = res.data.list;
          params.render(res.data.total);
        }
        this.listLoading = false;
      })
    },

    handleUserView(row) {
      let { href } = this.$router.resolve({
        path: `/crowdManagement/crowdUserResult/${row.crowdNo}`
      });
      window.open(href, "_blank");
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box {
    padding: 0 20px;
    .search_box {
      background: #fff;
      padding: 20px;
      margin-top: 20px;
    }
    .table_nav_bar{
      width: 100%;
      background: #fff;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
      .el-button{
        margin-right: 10px;
      }
    }
    .table_row_rule{
      word-break: break-all; /** 换行，长单词会分成两行显示 **/
      text-overflow: ellipsis;/** 隐藏文本省略号 ... **/
      display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
      -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
      -webkit-line-clamp: 1; /** 显示的行数 **/
      overflow: hidden;  /** 隐藏超出的内容 **/;
    }
  }
  .handle_btn{
    padding: 0;
  }
  .channel_item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    >.el-input:nth-child(1){
      width: 260px;
    }
  }
  .channel_item:last-child{
    margin: 0;
  }
  .popularize_list_qrcode{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .qrcode_img_src{
    position: absolute;
    top: -10000%;
    z-index: -10000;
  }
  .search_item{
    display: flex;
    align-items: center;
    >div:first-child{
      flex-shrink: 0;
    }
  }
  ::v-deep .el-table__header thead tr th:first-of-type, .el-table__body tbody tr td:first-of-type{
    padding: 0 !important;
  }
  ::v-deep .el-table__body tbody tr td:first-of-type {
    padding-left: 0px !important;
  }
</style>
